import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useState, useEffect } from "react";

const InputContainer = styled.div`
  display: block;
  margin: auto;
  margin-bottom: 2rem;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
`;

const Submit = styled.button`
  display: block;
  background-color: white;
  color: ${colors.blue};
  border: 1px solid ${colors.blue};
  width: 100%;
  margin-top: 1rem;
  border-radius: 1rem;
  &:focus,
  &:hover {
    background-color: ${colors.blue};
    color: white;
  }
`;
const Label = styled.label`
  display: block;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
  }
`;

const Checkbox = styled.input`
  height: 17px;
  width: 17px;
  margin: 0.5rem;
`;

const Select = styled.select`
  color: ${colors.blue};
  background-color: white;
  font-size: 10px;
  border: solid 1px #d7dae1;
  width: 100%;
  font-size: 1rem;
  height: 37px;
`;

const search = () => {
  
  const data = useStaticQuery(graphql`
    {
      issue_pages: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Issue Page" } } } }
        }
        sort: { fields: title, order: ASC }
      ) {
        distinct(field: title)
      }
      campaigns: allWpPost(
        sort: { fields: nonprofitPageMetadata___serviceProjectType, order: ASC }
      ) {
        distinct(field: nonprofitPageMetadata___serviceProjectType)
      }
      age_group: allWpPost(
        sort: { fields: nonprofitPageMetadata___ageGroup, order: ASC }
      ) {
        distinct(field: nonprofitPageMetadata___ageGroup)
      }
    }
  `);

  const issues = data?.issue_pages?.distinct;
  const campaigns = data?.campaigns?.distinct;
  const age_group = data?.age_group?.distinct;

  const [search, setSearch] = useState("");
  const [issue, setIssue] = useState("");
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate("../../search-for-an-organization/results", {
      state: { search, issue, selectedCampaigns, selectedAges },
    });
  };

  const handleCampaignCheckbox = (i: number) => {
    var new_campaigns = selectedCampaigns;
    const clickedBox = campaigns[i];
    const index = new_campaigns.indexOf(clickedBox);
    if (index > -1) {
      new_campaigns.splice(index, 1);
    } else {
      new_campaigns.push(clickedBox);
    }
    setSelectedCampaigns(new_campaigns);
  };

  const handleAgeCheckbox = (i: number) => {
    var new_ages = selectedAges;
    const clickedBox = age_group[i];
    const index = new_ages.indexOf(clickedBox);
    if (index > -1) {
      new_ages.splice(index, 1);
    } else {
      new_ages.push(clickedBox);
    }
    setSelectedAges(new_ages);
  };

  return (
    <Layout title={"Find a Service Project"}>
      <SEO title="Find a Service Project" />
      <SearchContainer>
        <FormContainer onSubmit={(e) => handleSubmit(e)}>
          <InputContainer>
            <Label htmlFor="keywords">Organization Name</Label>
            <Input
              id="keywords"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></Input>
          </InputContainer>
          <InputContainer>
            <Label htmlFor="company_issue">Who would you like to help?</Label>
            <Select
              id="company_issue"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            >
              <option key={-1} value={""}>
                {""}
              </option>
              {issues?.map((issue: any, i: number) => (
              <option key={i} 
                value={issue} > 
                {issue} 
              </option>))}
            </Select>
          </InputContainer>
          <InputContainer>
            <Label htmlFor="campaign">What would you like to do?</Label>
            {campaigns?.map((campaign: any, i: number) => (
              <div>
                <Checkbox
                  id={campaign}
                  type="checkbox"
                  key={i}
                  onChange={(campaign) => handleCampaignCheckbox(i)}
                />
                <label htmlFor={campaign}>{campaign}</label>
              </div>
            ))}
          </InputContainer>
          <InputContainer>
            <Label>Who can help?</Label>
            {age_group?.map((age: any, i: number) => (
              <div>
                <Checkbox
                  id={age}
                  type="checkbox"
                  key={i}
                  onChange={(age) => handleAgeCheckbox(i)}
                />
                <label htmlFor={age}>{age}</label>
              </div>
            ))}
          </InputContainer>
          <InputContainer>
            <Submit type="submit">Go!</Submit>
          </InputContainer>
        </FormContainer>
      </SearchContainer>
    </Layout>
  );
};

export default search;
